import React from 'react'
import Icon from '@ant-design/icons'
import { ReactComponent as Light } from '../assets/icons/lighticon.svg'
import { ReactComponent as Dark } from '../assets/icons/darkicon.svg'
import { ReactComponent as GymApple } from '../assets/icons/gym_apple.svg'
import { ReactComponent as GymDumbbell } from '../assets/icons/gym_dumbbell.svg'
import { ReactComponent as GymGloves } from '../assets/icons/gym_gloves.svg'
import { ReactComponent as GymTraining } from '../assets/icons/gym_training.svg'
import { ReactComponent as GymAppleLight } from '../assets/icons/gym_apple_light.svg'
import { ReactComponent as GymDumbbellLight } from '../assets/icons/gym_dumbbell_light.svg'
import { ReactComponent as GymGlovesLight } from '../assets/icons/gym_gloves_light.svg'
import { ReactComponent as GymTrainingLight } from '../assets/icons/gym_training_light.svg'
import { ReactComponent as BoxingGloves } from '../assets/icons/boxing_gloves.svg'
import { ReactComponent as LocationPin } from '../assets/icons/location_pin.svg'
import { ReactComponent as Email } from '../assets/icons/email.svg'
import { ReactComponent as Facebook } from '../assets/icons/facebook.svg'
import { ReactComponent as Instagram } from '../assets/icons/instagram.svg'
import { ReactComponent as Whatsapp } from '../assets/icons/whatsapp.svg'
import { ReactComponent as Youtube } from '../assets/icons/youtube.svg'
import { ReactComponent as TikTok } from '../assets/icons/tiktok.svg'

export const LightIcon = (props: any) => <Icon component={Light} {...props} />
export const DarkIcon = (props: any) => <Icon component={Dark} {...props} />
export const GymAppleIcon = (props: any) => <Icon component={GymApple} {...props} />
export const GymDumbbellIcon = (props: any) => <Icon component={GymDumbbell} {...props} />
export const GymGlovesIcon = (props: any) => <Icon component={GymGloves} {...props} />
export const GymTrainingIcon = (props: any) => <Icon component={GymTraining} {...props} />
export const GymAppleLightIcon = (props: any) => <Icon component={GymAppleLight} {...props} />
export const GymDumbbellLightIcon = (props: any) => <Icon component={GymDumbbellLight} {...props} />
export const GymGlovesLightIcon = (props: any) => <Icon component={GymGlovesLight} {...props} />
export const GymTrainingLightIcon = (props: any) => <Icon component={GymTrainingLight} {...props} />
export const BoxingGlovesIcon = (props: any) => <Icon component={BoxingGloves} {...props} />
export const LocationPinIcon = (props: any) => <Icon component={LocationPin} {...props} />
export const EmailIcon = (props: any) => <Icon component={Email} {...props} />
export const FacebookIcon = (props: any) => <Icon component={Facebook} {...props} />
export const InstagramIcon = (props: any) => <Icon component={Instagram} {...props} />
export const WhatsappIcon = (props: any) => <Icon component={Whatsapp} {...props} />
export const YoutubeIcon = (props: any) => <Icon component={Youtube} {...props} />
export const TikTokIcon = (props: any) => <Icon component={TikTok} {...props} />